<template>
  <div class="ey">
    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-4col grid-block-left-margin-1col grid-medium-full">
        <div class="bg-image-container">
          <img
            alt="EY tool navigation screen"
            class="bg-image"
            src="~@/assets/images/EY__bg_lg.png"
            srcset="
              ~@/assets/images/EY__bg_sm.png  513w,
              ~@/assets/images/EY__bg_md.png  433w,
              ~@/assets/images/EY__bg_lg.png  633w,
              ~@/assets/images/EY__bg_xl.png 1077w
            "
            sizes="(max-width: 599px) 513px, (max-width: 899px) 432px, (max-width: 1439px) 633px, (min-width: 1440px) 1077px"
          />
        </div>
      </div>
    </div>
    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-3col">
        <!-- main subheading -->
        <h1 class="utopia-blk-headline main-subheading">
          Beginnings, middles and ends, but not necessarily in that order:
        </h1>
      </div>
    </div>
    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-4col">
        <!-- main title -->
        <h2 class="utopia-blk-headline main-title">
          Making connections: Bringing complex data and insight to life for EY
        </h2>
      </div>
    </div>
    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col grid-medium-4col">
        <!-- intro body -->
        <div class="body-copy-group body-copy-group__1">
          <p class="standfirst slate-medium">
            The world is complicated. Possibly uniquely amongst animals, humans make medium and
            long-term plans which rely on mapping that complexly interconnected world. We use many
            overlapping ways of understanding these connections in order to make predictions about
            the outcomes of complex systems.
          </p>
          <p class="slate-light body-copy">
            Global business advisors EY mapped-out connections influencing the behaviour of
            consumers to enable them to build a vision of the future consumer - what the consumer of
            the near, and not-so-near future will look like. This deeply researched and fascinating
            approach offers insights, new directions and new understanding. It is building EY's
            position as market leaders in the Consumer sector.
          </p>
          <p class="slate-light body-copy">
            But how do you bring this complex and interconnected data to life?
          </p>
          <p class="slate-light body-copy">
            We pitched, then developed a dynamic, interactive tool that allows EY practitioners to
            dive into the content, and tell compelling stories to their clients and colleagues. They
            can take any journey they want, starting wherever they want, and showing the links
            between the different personas, worlds and ideas.
          </p>
        </div>
      </div>
    </div>

    <!-- video -->
    <div class="with-caption video-with-caption">
      <div class="newgrid newgrid-no-row-gap newgrid-small-col-reverse">
        <div
          class="grid-block grid-block-1col grid-block-right-padding grid-block-small-full video-with-caption__container"
        >
          <div class="caption-group">
            <h4 class="caption-title utopia-caption">EY FCN in action</h4>
            <p class="caption-body slate-light">
              The interface starts with connections to the future Smart Consumer, and allows us to
              dive into the detail of how brands must prepare
            </p>
          </div>
        </div>
        <div class="grid-block grid-block-4col grid-block-small-full">
          <app-video
            class="with-caption__media"
            :poster="require('@/assets/videos/EY__demo-placeholder.png')"
            :sources="[{ src: require('@/assets/videos/EY__demo.mp4'), type: 'mp4' }]"
          />
        </div>
      </div>
    </div>

    <div class="newgrid">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col">
        <!-- image intro  -->
        <div class="body-copy-group body-copy-group__2">
          <h3 class="subtitle utopia-blk-headline">A unique touch interface</h3>
          <p class="slate-light body-copy">
            We always design with the end user in mind, and for EY we were keen to take advantage of
            their global network of wavespaces - hubs designed to deliver interactive, impactful and
            transformative experiences for clients. In these spaces, they had huge touch-screen
            displays where EY practitioners could guide clients around the world of the Future
            Consumer, creating discussion points and fluidly responding to questions and concerns.
          </p>
        </div>
      </div>
    </div>

    <!-- image -->
    <div class="newgrid with-caption newgrid-small-col">
      <div
        class="image-with-caption grid-block grid-block-4col grid-block-small-full grid-block-right-padding"
      >
        <img
          alt="EY Wavespace in Madrid"
          class="with-caption__media"
          src="~@/assets/images/EY__wavespace_lg.png"
          srcset="
            ~@/assets/images/EY__wavespace_sm.png  288w,
            ~@/assets/images/EY__wavespace_md.png  432w,
            ~@/assets/images/EY__wavespace_lg.png  633w,
            ~@/assets/images/EY__wavespace_xl.png 1076w
          "
          sizes="(max-width: 599px) 288px, (max-width: 899px) 432px, (max-width: 1439px) 633px, (min-width: 1440px) 1076px"
        />
      </div>
      <div class="grid-block grid-block-1col grid-block-small-full">
        <div class="caption-group">
          <h4 class="caption-title utopia-caption">Wavespace, Madrid</h4>
          <p class="slate-light caption-body slate-light">
            The interface is often rendered on these huge displays, in facilitated, "lean-back"
            conversations with many people at once. The design must be impactful and simple, with
            low information density per screen
          </p>
        </div>
      </div>
    </div>

    <div class="newgrid newgrid-no-row-gap">
      <div class="body-copy-group__3 grid-block grid-block-left-margin-1col grid-block-3col">
        <!-- numbers intro -->
        <p class="slate-light body-copy">
          We designed a beautiful interface, showing the many connections between the products,
          personas and worlds of the future consumer. Menus are stripped back and simple, while
          hinting at the depths of connection below. Sophisticated animation draws the user through
          the connections and provides subtle hints for where to go next.
        </p>
      </div>
    </div>

    <!-- animated numbers -->
    <div class="newgrid">
      <div class="grid-block grid-medium-full">
        <animated-numbers :numbers-data="numbersData" />
      </div>
    </div>

    <!-- carousel intro -->
    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col">
        <div class="body-copy-group body-copy-group__4">
          <h3 class="subtitle utopia-blk-headline">The design challenge</h3>
          <p class="slate-light body-copy">
            It was important to wow those watching a trained EY facilitator swiping and dragging
            content and connections on the screen. The interface needed to be slick and beautiful,
            providing an impactful “lean back” experience.
          </p>
          <p class="slate-light body-copy">
            We were all keen to avoid the “Minority report” clichés of Hollywood-style display
            interfaces, and it was important for the tool to be clearly EY branded. Instead we
            focused on a limited, bold colour palette, rich, human photography and a simplified set
            of display patterns, into which we could pour the content, small amounts at a time. The
            result is a design that can flex and grow with new and different content, while
            maintaining its composure.
          </p>
        </div>
      </div>
    </div>

    <!-- carousel -->
    <image-carousel :carousel-data="carouselData" />

    <div class="newgrid newgrid-no-row-gap">
      <div class="grid-block grid-block-left-margin-1col grid-block-3col outro">
        <!-- outro -->
        <div class="body-copy-group body-copy-group__5">
          <h3 class="subtitle utopia-blk-headline">Data. Managed.</h3>
          <p class="slate-light body-copy">
            With such a lot of data, and more coming in all the time, we built a bespoke Content
            Management System to allow any part of the system to be updated and refreshed. This
            means the tool is able to keep up with the speed of EY’s research and thinking. We’re
            also able to turn the interface to new types of data, to support the whole of EY.
          </p>
          <h3 class="subtitle utopia-blk-headline">Close collaboration</h3>
          <p class="slate-light body-copy">
            We brought this to life by working with many parts of EY, talking with consultants,
            knowledge practitioners, knowledge specialists and marketing professionals from three
            different continents. It was crucial to bring this group of fiercely intelligent and
            creative people along with us as we sought to bring their imagination to life. By
            working in short, iterative sprints, and showing regular progress in a transparent, open
            way, we could keep everyone onside and still deliver a finished product to be proud of.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedNumbers from "@/components/AnimatedNumbers";
import ImageCarousel from "@/components/ImageCarousel";
import AppVideo from "@/components/AppVideo";

export default {
  name: "EYArticle",
  data() {
    return {
      numbersData: [
        {
          number: 8,
          title: "Hypotheses",
        },
        {
          number: 15,
          title: "Future Worlds",
        },
        {
          number: 64,
          title: "Future Products",
        },
        {
          number: 5,
          title: "Business Imperatives",
        },
        {
          number: 150,
          title: "Drivers of Change",
        },
        {
          number: 15,
          title: "Future Personas",
        },
      ],
      carouselData: [
        {
          imagePath: "ey-carousel/timelines",
          title: "Navigation",
          description: "Entities are linked through animation, placement and connecting lines",
          alt: "Screenshot of the all Timelines page",
        },
        {
          imagePath: "ey-carousel/blockchain",
          title: "Navigation",
          description: "Entities are linked through animation, placement and connecting lines",
          alt: "Screenshot of a bridge page",
        },
        {
          imagePath: "ey-carousel/changying",
          title: "Personas",
          description: "15 separate future personas are described",
          alt: "Screenshot of Changying's persona page",
        },
        {
          imagePath: "ey-carousel/summary",
          title: "Future Worlds",
          description: "Detail screens discuss the changes, and their implications for business",
          alt:
            "Screenshot of a Takeaways and Implications section from the Experience Everything world",
        },
        {
          imagePath: "ey-carousel/move",
          title: "Future Worlds",
          description: "Detail screens discuss the changes, and their implications for business",
          alt: "Screenshot of the summary section of the Move hypothesis",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Making connections: Bringing complex data and insight to life for EY",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
        {
          vmid: "ogUrl",
          property: "og:url",
          content: "https://www.tuimedia.com" + this.$route.fullPath,
        },
        {
          property: "og:type",
          content: "website",
          vmid: "ogType",
        },
        {
          vmid: "ogTitle",
          property: "og:title",
          content: "Making connections: Bringing complex data and insight to life for EY",
        },
        {
          vmid: "ogDescription",
          property: "og:description",
          content:
            "A lean-back touch interface for visualising the drivers, personas and new worlds of the future consumer. Tui designed and built this content managed knowledge visualisation tool for Global business advisory firm EY.",
        },
        {
          property: "og:image",
          content: "https://www.tuimedia.com" + require("@/assets/images/EY_thumbnail.png"),
          vmid: "ogImage",
        },
        {
          property: "og:image:width",
          content: 256,
          vmid: "ogImageWidth",
        },
        {
          property: "og:image:height",
          content: 256,
          vmid: "ogImageHeight",
        },
        {
          property: "og:site_name",
          content: "Tui Media",
        },
        {
          vmid: "twitterCard",
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:image",
          content: "https://www.tuimedia.com" + require("@/assets/images/EY_thumbnail.png"),
          vmid: "twitterImage",
        },
        {
          name: "twitter:image:alt",
          content: "EY Thumbnail Graph",
          vmid: "twitterImageAlt",
        },
        {
          vmid: "twitterTitle",
          name: "twitter:title",
          content: "Making connections: Bringing complex data and insight to life for EY",
        },
        {
          vmid: "twitterSite",
          name: "twitter:site",
          content: "@tui_uk",
        },
        {
          vmid: "themeColor",
          name: "theme-color",
          content: "#1A1A24",
        },
      ],
    };
  },
  components: {
    AnimatedNumbers,
    ImageCarousel,
    AppVideo,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/objects.media-with-caption";

.ey {
  color: #fff;
  margin-bottom: 96px;

  // small
  @media screen and (max-width: $medium - 1) {
    margin-bottom: 32px;
  }
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 64px;
  }
}

.bg-image {
  position: absolute;
  top: -48px;
  opacity: 0.25;

  @media screen and (max-width: $medium - 1) {
    min-width: 513px;
    min-height: 301px;
    left: -78px;
    top: -60px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    left: 26%;
    top: -60px;
  }

  &-container {
    position: relative;
    & ~ * {
      z-index: 1;
    }
  }
}

.main-subheading {
  color: $ey-yellow;

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 24px;
    line-height: 27px;
    margin-top: 48px; // 18px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    grid-column: 1 / 5;
    font-size: 26px;
    line-height: 30px;
    margin-top: 24px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 32px;
    line-height: 36px;
    margin-top: 24px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 40px;
    line-height: 44px;
    margin-top: 48px;
  }
}

.main-title {
  grid-column: 1 / 5;

  // small
  @media screen and (max-width: $medium - 1) {
    grid-column: 1 / -1;
    font-size: 33px;
    line-height: 36px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 41px;
    line-height: 44px;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 48px;
    line-height: 51px;
    margin-top: 8px;
    margin-bottom: 38px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 64px;
    line-height: 64px;
    margin-top: 8px;
    margin-bottom: 56px;
  }
}

.standfirst {
  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 21px;
    line-height: 31px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 19px;
    line-height: 25px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 21px;
    line-height: 27px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 24px;
    line-height: 35px;
  }
}

.body-copy {
  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 18px;
    line-height: 28px;

    &-group__1 {
      margin-bottom: 26px;
    }
    &-group__4 {
      margin-top: 24px;
      margin-bottom: 16px;
    }
    &-group__5 {
      margin-top: 32px;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 15px;
    line-height: 21px;

    &-group__1 {
      margin-bottom: 32px;
    }
    &-group__4 {
      margin-top: 16px;
      margin-bottom: 40px;
    }
    &-group__5 {
      margin-top: 40px;
    }
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 16px;
    line-height: 24px;

    &-group__1 {
      margin-bottom: 48px;
    }

    &-group__2 {
      margin-top: 8px;
    }
    &-group__4 {
      margin-top: 24px;
      margin-bottom: 64px;
    }
    &-group__5 {
      margin-top: 64px;
    }
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 18px;
    line-height: 27px;

    &-group__1 {
      margin-bottom: 80px;
    }

    &-group__4 {
      margin-top: 32px;
      margin-bottom: 80px;
    }
    &-group__5 {
      margin-top: 80px;
    }
  }

  &-group__3 {
    grid-column: 1 / -1;
  }
}

.standfirst,
.body-copy,
.subtitle,
.body-copy-group {
  grid-column: 2 / 5;

  @media screen and (max-width: $medium - 1) {
    grid-column: 1 / -1;
  }

  /* at medium widths the content-well spans 4 out of the 5 columns */
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    grid-column: 2 / -1;
  }
}

.subtitle + p {
  margin-top: 11px;
}

.video-with-caption {
  background: #000;

  // small
  @media screen and (max-width: $medium - 1) {
    margin-bottom: 32px;

    &__container {
      margin-bottom: 16px;
      padding-right: 0;
    }
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 32px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    margin-bottom: 67px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    margin-bottom: 96px;
  }
}

.image-with-caption {
  @media screen and (max-width: $medium - 1) {
    padding-right: 0;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    margin-bottom: 16px;
  }
  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    margin-bottom: 24px;
  }
  // extra-large
  @media screen and (min-width: $extra-large) {
    /*  margin-top: -16px; */
    margin-bottom: 32px;
  }

  & > img {
    width: 100%;
  }
}

.outro {
  // small
  @media screen and (max-width: $medium - 1) {
    margin-top: 24px;
  }
}
</style>
